

@include media-breakpoint-up(sm)  {
	
	.breadcrumb, .navbar-icon {
		margin-left: 26px;
	}

	.navbar-togglers {
		margin-right: 26px;
	}
}

@include media-breakpoint-up(md)  {

	.kdTopNav ul li {
		margin-right: 15px;
		margin-left: 15px;
	}

	.nav-link, .dropdown-item {
		font-size: 1.2em;
	}


	.home-display h1 {
	font-size: 4rem;
	line-height: 4.4rem;
	}

	h1 {
		font-size: 2.4em;
		line-height: 	1.4em;
	}

	.jumbotron {
		background: transparent;
		padding: 0 50px;
	}

	.top-display {
	font-size: 4em;
	line-height: 1.4em;
	}

	.largequote {
		color: $tertiary;
	    font-size: 4.2rem;
	    line-height: 1.4em;
	}

	#howIhelpyou {
		margin-top: -40px;
	}

	.image-text {
		top: 50%;
		left: 30%;
		transform: translate(-20%, -50%);
		font-size: 2.4rem;
		line-height: 1.6em;
	}


}



@include media-breakpoint-up(lg) {

	.kdTopNav {
		padding-right: 60px;
		padding-left: 60px;
	}
	.kdTopNav ul  {
		font-size: 1.6em;
	}
	.kdTopNav ul li {
		margin-right: 20px;
	}


	.navbar-nav {
		margin-top: 20px;
	}

	.nav-link, .dropdown-item {
	font-size: 1.2rem;
	text-align: left;
	}

	.nav-link {
		border: none;
	}

	.kdMainRow {
	padding: ($spacer * 6);

	}

	.extraspace {
			padding: ($spacer * 6);
	}

	#howIhelpyou {
		margin-top: -50px;
	}


	.image-text {
		color: $tertiary;
		font-size: 2.6rem;
		line-height: 1.6em;
	}

}


@include media-breakpoint-up(xl)  {

		.nav-link, .dropdown-item {
		font-size: 1.4rem;
		}

		.nav-link {
		margin: 0 5px 0 5px;
		}

		footer .nav-link {
			font-size: 1.6rem;
		}

		h3 {
			line-height: 	$base__line-height*1.3;
		}

	.homecharacter {
		margin-top: -30px;
		margin-bottom: -50px;
	}

}


@include media-breakpoint-up(xxl) {

	h1 {
		line-height: $base__line-height*2;
	}

	h1.home-display {
	font-size: 5rem;
	margin-top: ($spacer * 4);
	}

	.large {
		font-size: 2rem;
		line-height: 3rem;
	}

	.card {
    margin-top: 0;
		}

}