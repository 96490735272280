// --------------------------------------------------
//
// Variables (or Custom Properties)
//
// --------------------------------------------------

/* set the overriding variables */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1800px
);

$container-max-widths: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1800px
);
 

//== Descriptive color variables

$acapulco		  :	#6aad9c; /* Mint of dee */
$cuttysark		:	#587678; /* Grey of kay */
$casal			  :	#2c6672; /* Dark turquoise blue */
$towergray		:	#a7b9bb; /* Light gray */
$capecod		  :	#414b4d; /* Dark gray */

$chestnutrose         :	#ce5c48;  /* Brick red */
$burningsand         : #e1947a;  /* Bright orange */
$champagne    		  :	#fbead3;

$gray-200		: 	#e9ecef; 
$gray-600		: 	#878472;

$white			: 	#fff;    
$black			: 	#000;
 
//====== Define Colors   

$primary        :	$cuttysark; /* Grey of kay */
$secondary      :	$acapulco; /* Mint of dee */
$tertiary 		:	$casal;  /* Dark turquoise blue */
$quaternary 	:	$capecod;    
$quinary        :	$towergray;

$popprimary 	:	$chestnutrose;
$notsobright	: 	$burningsand;
$light	        : 	$champagne;


$theme-colors: (
  "success": #96B321,
  "danger": #ff4136,
  "warning": #556B00
);

$primary-light: lighten($primary, 10%);
$primary-dark: darken($primary, 10%);

$popprimary-light: lighten($popprimary, 10%);
$popprimary-dark: darken($popprimary, 10%);

$secondary-light: lighten($secondary, 10%); 
$secondary-dark: darken($secondary, 10%); 

$tertiary-light: lighten($tertiary, 10%); 
$tertiary-dark: darken($tertiary, 10%); 

$quaternary-light: lighten($quaternary, 10%); 
$quaternary-dark: darken($quaternary, 10%); 

$quinary-light: lighten($quinary, 10%); 
$quinary-dark: darken($quinary, 10%); 

$notsobright-light: lighten($notsobright, 10%);
$notsobright-dark: darken($notsobright, 10%);

$black-lighten: lighten($black, 35%);
$white-darken: darken($white, 20%);


$dropdown-bg:                       $quinary;

$dropdown-link-color:               darken($white, 10%);
$dropdown-link-hover-color:         darken($white, 15%);
$dropdown-link-hover-bg:            darken($quinary, 3%);


$dropdown-link-active-bg:           darken($quinary, 6%);


//== Borders

$border-radius-extra-lg:      .8rem;

//== Social Colors

$facebook			:		#3b5998;
$facebook-light		: 		lighten($facebook, 25%);
$twitter			:		#1DA1F2;
$twitter-light		: 		lighten($twitter, 25%);
$google				:		#DB4437;
$google-light		: 		lighten($google, 25%);
$instagram			:		#262626;
$instagram-light	: 		lighten($instagram, 25%);
$linkedin			:		#0077B5;
$linkedin-light		: 		lighten($linkedin, 25%);
$pinterest			:		#BD081C;
$pinterest-light	: 		lighten($pinterest, 25%);
$youtube			:		#ff0000;
$youtube-light		: 		lighten($youtube, 25%);

$social-btn-padding-y:         .2rem;
$social-btn-padding-x:         .5rem;
$social-btn-line-height:       .9rem;

$display5-size:               3rem;
$display6-size:               2.5rem;

$display5-weight:             300;
$display6-weight:             300;


