/* Home Jumbotron */

.jumbotron {
  background: transparent;
  padding: 0 30px ;
}

.home-display h1 {
  font-size: 2.0rem;
  line-height: 3rem;
}

.homecharacter {
  margin-top: -20px;
  margin-bottom: -60px;
}


/* Bottom of homepage */

.travelling-kaydee {
  background-image: url('/assets/website-designer-travel-industry.png');
  background-position: center;
  min-height: 1053px;
}

.journey-online {
  font-size: 2.8em;
  line-height: 1.2em;
  color: $tertiary;
}

@include media-breakpoint-up(xl) {

  .journey-online {
    font-size: 3.8em;
    line-height: 1.2em;
    width: 600px;
    padding-top: 20px;
  }

}


//
// Boxes
// --------------------------------------------------

.homenumbers {
  font-size: 4rem;
  margin-bottom: 20px;
}

.homepagebox {
  border: none;
  transition: transform .2s;
  z-index: 10;
  padding: 10px;

  h2 {
    font-size: 2em;
  }
    &:hover {
      transform: scale(1.03);
      background: $gray-200;
    }
}

.homeline {
  width: 100%;
}
