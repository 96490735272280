
//
// Buttons
// --------------------------------------------------


.btn {
	padding: 14px 20px;
	margin-top: 20px;
	color: white;
	text-decoration: none;
}

.btn-primary {

	&:hover {
		background-color: $primary-light;
		border: $primary-light;
	}

	&:active {
		background-color: $primary-light;
		border: $primary-light;
	} 
}
.btn-popprimary {
	color: white;
	background-color: $popprimary;
	border: $popprimary;

	&:hover {
		background-color: $popprimary-light;
		border: $popprimary-light;
	}

	&:active {
		background-color: $popprimary-light;
		border: $popprimary-light;
	}
}
.homepagebox .btn {
	color: white;
}

.btn-tertiary {
    background: $tertiary;
	border: $tertiary;
	color: white !important;

	&:hover {
		background-color: $tertiary-dark;
		border: $tertiary-dark;
		color: $white-darken;
	}

	&:active {
		background-color: $tertiary-dark;
		border: $tertiary-dark;
		}

}

.btn-quinary {
    background: $quinary;
	border: $quinary;

	&:hover {
		background-color: $quinary-dark;
		border: $quinary-dark;
		color: $white-darken;
	}

	&:active {
		background-color: $secondary-dark;
		border: $secondary-dark;
		}

}



.btn-blog  {

    background: $primary;
	border: $primary;

	&:hover {
		background-color: $secondary-dark;
		border: $secondary-dark;
	}

	&:active {
		background-color: $secondary-dark;
		border: $secondary-dark;
	}

}

.btn-bright  {
    background: $popprimary;
	border: $popprimary;
	color: white;

	a {
		color: white;
	}

	&:hover {
		background-color: $popprimary-dark;
		border: $popprimary-dark;
	}

	&:active {
		background-color: $popprimary-dark;
		border: $popprimary-dark;
	}

}


.btn-notsobright  {

    background: $notsobright;
	border: $notsobright;
	color: white;

	&:hover {
		background-color: $notsobright-dark;
		border: $notsobright-dark;
	}

	&:active {
		background-color: $notsobright-dark;
		border: $notsobright-dark;
	}

}


.btn-social {

	padding: $social-btn-padding-y $social-btn-padding-x;
	line-height: $social-btn-line-height;
	
}

.btn-facebook {
	color: $facebook !important;

	&:hover {
		color: $facebook-light;
	}

}

.btn-twitter {
	color: $twitter !important;

		&:hover {
		color: $twitter-light;
	}
}

.btn-google {
	color: $google !important;

		&:hover {
		color: $google-light;
	}
}

.btn-instagram {
	color: $instagram !important;

		&:hover {
		color: $instagram-light;
	}
}

.btn-linkedin {
	color: $linkedin !important;

		&:hover {
		color: $linkedin-light;
	}
}

.btn-pinterest {
	color: $pinterest !important;

		&:hover {
		color: $pinterest-light;
	}
}

.btn-youtube {
	color: $youtube !important;

		&:hover {
		color: $youtube-light;
	}
}

