// --------------------------------------------------
//
// Fonts
//
// --------------------------------------------------

// Font Stacks

$font-url-google		:	'https://fonts.googleapis.com/css2?family=Poppins:wght@300;900&display=swap';


$font-family-body		:		'Poppins', sans-serif;
$headings-font-family  	:		'Poppins', sans-serif;

//==== Fonts

$base__font-size		:		17px;
$base__line-height		:		32px;
$headings-line-height		:		44px;


body {
	font-family:  	$font-family-body;
	line-height: 	$base__line-height;
	font-size: 		$base__font-size;
}

body p a {
	text-decoration: underline;
	color: $popprimary;
}

.card a {
	color: white;
}

.homepagebox a {
	color: $popprimary;
}

.btn, .navbar-nav, .contact-kd {
	font-family:  $headings-font-family; 
}

.top-display {
	margin-top: 3em;
	font-size: 2em;
	line-height: 3rem;
	color: $tertiary;
}

h1 {
	font-size: 2em;
	line-height: 	1.4em;
	margin-bottom: 	20px;
	color: $tertiary;
}

h2 {
	line-height: $base__line-height*1.4;
	color: $quaternary;
}


h3 {
	margin: 0.5em 0 0.5em 0;
	line-height: 	$base__line-height*1.2;
}

h4 {
	line-height: $base__line-height;
}

.slightincrease {
	font-size: 140%;
	line-height: 2.2rem;
}

.large {
    font-size: 1.8rem;
    line-height: 3rem;
}

.largequote {
	color: $tertiary;
    font-size: 2.6rem;
    line-height: 1.6em;
	font-weight: 900;
}

.mediumquote {
	color: $tertiary;
    font-size: 2.2rem;
    line-height: 1.2em;
}

.font-spacing {
	letter-spacing: 0.2rem;
}
