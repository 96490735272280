
html, body {
  height: 100%; /* Here for the links.kaydee.net page */
}

//
// CSS
// --------------------------------------------------


::selection {
  background: $primary; /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: $primary; /* Gecko Browsers */
}

.kdTopNav {
  padding: ($spacer);
  margin: 0px;
  background-color: $tertiary;
}
.kdTopNav ul  {
  margin-left: -40px;
  text-transform: uppercase;
  font-size: 1.6em;
}
.kdTopNav ul li {
  display: inline;
  margin-right: 20px;
}

.top-nav-item {
  color: white;
}

  /* Navbar */

nav {
  z-index: 10;
}

ul.navbar-nav {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

.nav-link {
  border-bottom: 1px $quinary solid;
}

.nav-link, .dropdown-item {
  color: $primary;
  text-align: left;
  font-size: 0.8em;
  padding: 20px;
}

.navbar-light .navbar-toggler {
  color: $tertiary;
  border: none;
}

.navbar .navbar-text, .navbar {
  color: $primary;
  font-size: 1.6em;
}

.dropdown-menu {
  background-color: ghostwhite;
  border: none;
  font-size: 0.9em;
}

.dropdown-item {
  padding: 10px 15px 10px 15px;
}

.footer-mobile-label {
  font-size: 2em;
  padding-left: 5px;
}

.footer-menu {
  padding-top: ($spacer * 3);
  padding-bottom: ($spacer * 3);
}

footer .nav-link {
  color: white !important;
}

.navy-blue-border {
  border: 4px solid #1d2837;
}

// Hamburger
// ==================================================
.hamburger {
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    } @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      } @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: 22px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 51%;
  margin-top: 0px;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: 10px;
  }

  &::after {
    bottom: 10px;
  }
}


/* Colours */

.primary {
  color: $primary;
}

.popprimary {
  color: $popprimary;
}

.secondary {
  color: $secondary;
}

.tertiary {
  color: $tertiary;
}

.quaternary {
  color: $quaternary;
}

.quinary {
  color: $quinary;
}

.bright {
  color: $popprimary;
}

.notsobright {
  color: $notsobright;
}

.light {
  color: $light;
}

.dark {
  color: $tertiary;
}


//
// Rows and Columns
// --------------------------------------------------

.background-gradient {
  background-image: linear-gradient(to top, $quinary, white);
}

.bg-gray, .column-gray {

  background-color: $gray-200;

  h3, p, ul {
    color: $black-lighten;
  }

}
.column-gray, .column-quinary {
  padding: ($spacer * 3);
}


.bg-tertiary {
  background-color: $tertiary;
  color: $white-darken;
    h2, .figure-caption, a {
    color: $white-darken;
    }
}

.bg-quaternary {
  background-color: $quaternary;
  color: $white-darken;
}

.bg-quaternary h2 {
  color: $white-darken;
}
.bg-quaternary .card h2 {
    color: $quaternary;
  }

.bg-quinary, .column-quinary {
  background-color: $quinary;
}

.kdMainRow {
  padding-left: ($spacer * 2);
  padding-right: ($spacer * 2);
  padding-top: ($spacer * 3);
  padding-bottom: ($spacer * 3);

}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid $tertiary;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019"; /* TODO */
}

.technical-seo-bg {
  background-image: url('technical-seo-kelly-drewett-france.jpg');
}

/* Background image */
.image-container {
  position: relative;
  text-align: center;
}

.image-text {
  position: absolute;
  top: 30%;
  left: 25%;
  transform: translate(-15%, -25%);
  background-color: white;
  padding: 10px 20px;
  color: $tertiary;
  font-size: 1.2rem;
  line-height: 1.6em;
  font-weight: 900;
}

/* Prices */

.prices-main h2 {
  font-size: 3rem;
  color: white;
}

.prices-box {
  padding: 10px;
}

.prices-box-inner {
  height: 100%;
  padding: 10px 20px;
  background-color: $white;
  border: 1px solid $primary;
}

.prices-box h3 {
  color: $tertiary;
  font-size: 2rem;
  font-weight: 600;
}

.prices-large {
  color: $secondary;
  font-size: 3rem;
  line-height: 3.4rem;
}

.prices-bottom {
  margin-bottom: 20px;
}

.BusinessFriends, .Charities {
  padding: $spacer;
  margin-bottom: $spacer;
  border: 1px solid $gray-400;
}

/* Images */

.imagelazywrap {
  display: inline-block;
  position: relative;
}

//
// List 
// -------------------------------------------------

.list-group-item, .list-group-item {
  list-style-type: none;
  margin-left: -50px;
}

.list-icon {
  margin-right: 10px;
}


//
// Cards
// --------------------------------------------------

.card {
  margin-top: $spacer;
}


.card-footer {
  background-color: transparent;
  border-top: none;
  padding-bottom: 30px;
}


/* Custom cards (not those on home page) */



.card-custom {
  background-color: $primary;
  padding: $spacer*2;
  border: none;

      h2, .card-title {
        color: $light;
      }

     h3, h4, h5, h6, p, ul, .card-body, .card-text, .card-custom-icon {
        color: white;
      }

      a:link {
        color: white;
      }

}

.card-white {
  background-color: white;
  padding: $spacer;
  color: $quaternary;
  border: none;

  p, .card-body, .card-title {
    color: $quaternary;
  }

}

.card-header {
  border: none;
  background-color: $white;
}




/* Tables */
.table-prices td {
  padding: 10px;
}

.table-first-column {
  width: 220px;
  text-align: start;
}

#_form_6209380A5EE55_ ._close-icon {
  color: $primary;
}



/* Breadcrumbs */

.breadcrumb {
  background: none;
  padding-bottom: 0;
  z-index: 30 !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #666;
  content: ">";
}

.divider {
  width: 129px;
  height: 5px;
  background: $popprimary;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

/* Case Studies/Portfolio */

.CaseStudy {
  padding: 0px 40px 0px 40px;
}

.CaseStudyTitle {
  height: 180px;
}

